
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'Detail'
})

export default class extends Vue {
  @Prop() readonly detailShow!: boolean
  @Prop() readonly transferId!: string

  private formData = {}
  protected loading = false

  @Watch('transferId')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryTransferByTransferId, { transferId: this.transferId }).then(res => {
      // console.log('详情', res)
      this.formData = res || {}
    }).finally(() => {
      this.loading = false
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('closeDialog')
    this.formData = {}
  }
}
