

import { Vue, Component } from 'vue-property-decorator'
import Detail from './Detail.vue'
import { KeepAlive } from '@/utils/decorators'
@Component({
  components: { Detail }
})
@KeepAlive
export default class InsectPest extends Vue {
  private departmentList = []
  private detailShow = false
  private transferId = ''
  private transferDate = []
  private ctime = []
  private searchInfo = {
    createUserName: '', // 调用申请人姓名
    transferDept: '', // 所属部门
    transferDateStart: '', // 使用日期开始
    transferDateEnd: '', // 使用日期结束
    ctimeStart: '', // 申请时间开始
    ctimeEnd: '', // 申请时间结束
    projectId: '' // 所属项目
  }

  private prop = {
    expandTrigger: 'hover',
    value: 'deptId',
    label: 'deptName',
    children: 'childNode',
    emitPath: false
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10
  private projectList = []

  created () {
    this.loadData()
    this.getProjectList()
    this.getDepartmentListGet()
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      // console.log('getProjectList', res)
      this.projectList = res
    })
  }

  getDepartmentListGet () {
    this.$axios.get(this.$apis.department.departmentList).then((res) => {
      this.departmentList = res || []
    })
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryCallByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  // 去新增页面
  goAdd () {
    this.$router.push({ name: 'callAdd' })
  }

  goEdit (transferId: any) {
    this.$router.push({ name: 'callEdit', params: { transferId } })
  }

  onDetail (transferId: any) {
    this.detailShow = true
    this.transferId = transferId
  }

  onDelete (transferId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryTransfer, { transferId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }

  selectTime1 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.transferDateStart = ''
      this.searchInfo.transferDateEnd = ''
    } else {
      this.searchInfo.transferDateStart = this.transferDate[0]
      this.searchInfo.transferDateEnd = this.transferDate[1]
    }
  }

  selectTime2 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.ctimeStart = ''
      this.searchInfo.ctimeEnd = ''
    } else {
      this.searchInfo.ctimeStart = this.ctime[0]
      this.searchInfo.ctimeEnd = this.ctime[1]
    }
  }

  closeDialog () {
    this.detailShow = false
    this.transferId = ''
  }
}
